/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap'); */
/* @import '@fortawesome/fontawesome-svg-core/styles.css'; */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  html {
    font-family: Nunito, sans-serif;
  }
} */


* {
  padding: 0;
  margin: 0;
}

#__next {
  height: 100%;
}

#root {
  height: 100%;
}


html {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  overflow-x: hidden;
  color: #333333;
  background-color: #333;
}

/* html,
body,
.wrapper,
main{
  height: 100%;
} */

h1,
h2,
h3,
h4,
h5,
h6,
label {
  color: #333333;
  font-weight: normal;
  line-height: 1.1;
  letter-spacing: 0.035em;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-weight: 600;
  font-size: 1.625rem;
  margin-top: 24px;
}

h3 {
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 24px;
}

p {
  font-size: 1rem;
  letter-spacing: 0.035em;
}

div {
  letter-spacing: 0.035em;
}

img {
  border: none;
  outline: none;
  max-width: 100%;
}

label {
  display: inline-block;
  font-weight: normal;
  margin-bottom: 5px;
  max-width: 100%;
  line-height: 1.1;
  letter-spacing: 0.035em;
}

.grecaptcha-badge {
  visibility: hidden;
}


/* a,
.btn,
button {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  outline: medium none;
} */

/* a img,
iframe {
  border: none;
} */

p {
  margin: 0 0 15px;
  text-transform: none;
  text-align: justify;
  font-weight: 400;
  color: #333333;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #eee;
}

pre {
  display: block;
  margin: 0 0 30px;
  padding: 9.5px;
  word-wrap: break-word;
  word-break: break-all;
  color: #333;
  border: 1px solid #ededed;
  border-radius: inherit;
  background-color: #f9f9f9;
  font-size: 13px;
  line-height: 1.42857143;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: inherit;
}

ul {
  margin: 0;
  list-style-type: none;
}

a,
a:active,
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
  /* color: #333333; */
}

a:hover {
  color: #333333;
}

a {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  text-decoration: none;
  /* font-family: 'Nunito', sans-serif; */
  font-weight: 700;
}

b,
strong {
  font-weight: 900;
}

header {
  position: relative;
}

